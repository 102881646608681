<script>
import { defineComponent } from 'vue';
import AntInput from '@/components/AntInput.vue';
import InputRulesMixin from '@/Mixins/InputRulesMixin';
import { mapGetters } from 'vuex';

export default defineComponent({
  name: 'DhmeManualImportCreateObjectDialog',
  components: { AntInput },
  mixins: [InputRulesMixin],
  props: {
    displayed: {
      type: Boolean,
      required: true,
    },
    category: {
      type: String,
      required: false,
      default: null,
    },
    object: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => {
    return {
      createObjectItem: {},
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters({
      objectRegister: 'dhmeManualImportStore/objectsRegister',
      elements: 'dhmeManualImportStore/elements',
    }),
    showDialog() {
      return this.displayed;
    },
    autocompleteDescriptionItems() {
      return this.objectRegister
        .map((item) => item.name)
        .sort((a, b) => a.localeCompare(b));
    },
    autocompleteSteelQualityItems() {
      return this.objectRegister.map((item) => item.steel_quality);
    },
    elementCategories() {
      return this.elements
        .map((item) => item.element_category)
        .sort((a, b) => a.localeCompare(b));
    },
  },
  watch: {
    showDialog(value) {
      if (value) {
        if (this.category) {
          this.createObjectItem.category = this.category;
        }
        if (this.object) {
          Object.assign(this.createObjectItem, this.object);
        }
      }
    },
  },
  methods: {
    closeDialog() {
      Object.assign(this.createObjectItem, {});
      this.$refs['create-object-form'].reset();
      this.$emit('closeDialog');
    },
    async createObject() {
      if (this.$refs['create-object-form'].validate()) {
        this.isLoading = true;
        if (this.createObjectItem.id) {
          await this.$store.dispatch(
            'dhmeManualImportStore/manualImportUpdateObjectRecord',
            {
              recordId: this.createObjectItem.id,
              recordBody: this.createObjectItem,
            }
          );
        } else {
          await this.$store.dispatch(
            'dhmeManualImportStore/manualImportCreateObjectRecord',
            {
              recordBody: this.createObjectItem,
            }
          );
        }

        this.isLoading = false;
        this.closeDialog();
      }
    },
  },
});
</script>

<template>
  <v-dialog
    v-model="showDialog"
    max-width="600"
    persistent
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title class="justify-center text-uppercase headline">
        Create object type
      </v-card-title>
      <v-divider />
      <div class="px-10 pb-5">
        <v-form ref="create-object-form">
          <div class="d-flex">
            <ant-input label="Code">
              <template #input-field>
                <v-text-field
                  v-model="createObjectItem.object_type"
                  placeholder="Code"
                  hide-details
                  dense
                  filled
                  single-line
                  :rules="[rules.required]"
                />
              </template>
            </ant-input>

            <ant-input label="Description" class="ml-2 flex-grow-1">
              <template #input-field>
                <v-combobox
                  v-model="createObjectItem.name"
                  :items="autocompleteDescriptionItems"
                  placeholder="description"
                  hide-details
                  dense
                  filled
                  single-line
                  :rules="[rules.required]"
                />
              </template>
            </ant-input>
          </div>

          <div class="d-flex">
            <ant-input label="Length" is-optional>
              <template #input-field>
                <v-text-field
                  v-model="createObjectItem.length"
                  placeholder="Length"
                  hide-details
                  dense
                  filled
                  single-line
                  suffix="mm"
                />
              </template>
            </ant-input>
            <ant-input label="Width" class="mx-2" is-optional>
              <template #input-field>
                <v-text-field
                  v-model="createObjectItem.width"
                  placeholder="Width"
                  hide-details
                  dense
                  filled
                  single-line
                  suffix="mm"
                />
              </template>
            </ant-input>
            <ant-input label="Height" is-optional>
              <template #input-field>
                <v-text-field
                  v-model="createObjectItem.height"
                  placeholder="height"
                  hide-details
                  dense
                  filled
                  single-line
                  suffix="mm"
                />
              </template>
            </ant-input>
          </div>
          <div class="d-flex">
            <ant-input label="Catgegory" class="mr-2 flex-grow-1">
              <template #input-field>
                <v-combobox
                  v-model="createObjectItem.category"
                  :items="elementCategories"
                  placeholder="category"
                  hide-details
                  dense
                  filled
                  single-line
                  :rules="[rules.required]"
                />
              </template>
            </ant-input>
            <ant-input is-optional label="Steel quality">
              <template #input-field>
                <v-combobox
                  v-model="createObjectItem.steel_quality"
                  :items="autocompleteSteelQualityItems"
                  placeholder="Quality lvl"
                  hide-details
                  dense
                  filled
                  single-line
                />
              </template>
            </ant-input>
          </div>
        </v-form>
      </div>
      <v-card-actions class="ant-dialog-actions-bg ant-border-top">
        <v-spacer />
        <v-btn color="error" text small @click="closeDialog"> Cancel </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          small
          :loading="isLoading"
          @click="createObject"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss"></style>
